const navToggleBtn = document.querySelectorAll('[data-nav-toggle]');
const subNavToggleBtn = document.querySelectorAll('.has-subnav .toggle-subnav');
const scrollToTopBtn = document.querySelector('[data-scroll-to-top]');

navToggleBtn?.forEach(button => {
    button.addEventListener('click', (event) => {
        event.target?.classList.toggle('active');
    });
});

subNavToggleBtn?.forEach(button => {
    button.addEventListener('click', (event) => {
        event.preventDefault();
        event.target?.parentElement?.classList.toggle('subnav-active');
    });
});

scrollToTopBtn?.addEventListener('click', (event) => {
    event.preventDefault();
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'});
});
