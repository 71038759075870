import { Swiper, Pagination, Navigation, EffectFade } from 'swiper';
Swiper.use([Pagination, Navigation, EffectFade]);

const sliders = document.querySelectorAll('[data-slider]');

sliders?.forEach(slider => {
    const effect = slider.getAttribute('data-slider-effect');
    const slidesPerView = slider.getAttribute('data-slider-slides-per-view');
    const showNavigation = slider.getAttribute('data-slider-show-navigation');
    const pagination = slider.querySelector('[data-slider-nav]');
    const bullets = pagination?.querySelectorAll('button');

    const options = {
        slidesPerView: slidesPerView || 'auto',
        effect: effect || 'slide'
    };

    if (pagination) {
        options.pagination = {
            el: pagination,
            clickable: true
        };
    }

    if (parseInt(showNavigation, 10)) {
        options.navigation = {
            nextEl: slider.querySelector('.swiper-button-next'),
            prevEl: slider.querySelector('.swiper-button-prev')
        };
    }

    // eslint-disable-next-line no-new
    new Swiper(slider, options);
});
