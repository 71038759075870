/**
 * Handles header search form toggle and search suggestions.
 */
class Search {
    body                    = document.querySelector('body');

    toggleSearchBtn         = document.querySelector('[data-search-toggle]');

    searchForm              = document.querySelector('.header .form-search');

    searchFormInput         = this.searchForm?.querySelector('input');

    searchContainer         = document.querySelector('.header .search-container');

    suggestionsContainer    = this.searchForm?.querySelector('.search-suggestions');

    constructor() {
        // Toggle search form open/close
        this.toggleSearchBtn?.addEventListener('click', (event) => {
            event.preventDefault();
            event.target?.classList.toggle('active');

            this.body.classList.toggle('primary-search-active');
            this.searchContainer?.querySelector('input')?.focus();
        });

        this.searchForm?.addEventListener('submit', (event) => {
            this.onSubmitSearchForm(event);
        });

        this.searchFormInput?.addEventListener('keyup', (event) => {
            this.onSearchFormInput(event);
        });
    }

    /**
     * Check the search form for an empty value.
     */
    onSubmitSearchForm(event) {
        const input = event.target.querySelector('input');

        if (input.value === '' || input.value === input.getAttribute('placeholder')) {
            event.preventDefault();

            alert(event.currentTarget.getAttribute('data-alert'));
        }
    }

    /**
     * Handle search suggestions.
     * Only fires if form attribute data-show-suggestions is set to 1.
     */
    onSearchFormInput(event) {
        const value     = event.target?.value;
        const form      = this.searchForm;

        if (!form.getAttribute('data-show-suggestions') || form.getAttribute('data-show-suggestions') == '0') {
            return;
        }

        if (value.length >= 3 && this.suggestionsContainer) {
            this.suggestionsContainer.innerHTML = '';
            this.suggestionsContainer.classList.remove('active');

            const url = '/assets/components/simplesearch/connector.php?action=web/autosuggestions&search=' + value;
            fetch(url, {
                method: 'get',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    Accept: 'application/json'
                }
            }).then(response => response.json()
            ).catch(error => console.error(error)
            ).then(data => {
                data?.results?.forEach(function (suggestion) {
                    const item = document.createElement('li');
                    const itemLink = document.createElement('a');

                    itemLink.setAttribute('href', '#');
                    itemLink.setAttribute('title', suggestion);
                    itemLink.innerHTML = suggestion;

                    item.appendChild(itemLink);

                    this.suggestionsContainer.append(item);

                    itemLink.addEventListener('click', (event) => {
                        this.searchFormInput.value = event.target.getAttribute('title');
                        this.searchForm.submit();
                    });
                });

                if (data?.results?.length > 0) {
                    this.suggestionsContainer.classList.add('active');
                }
            });
        }
    }
}

export default new Search();
