const socialShareContainer = document.querySelector('.social-share');
const socialShareUrl = document.querySelector('[data-social-share-url]');
const socialShareToggle = document.querySelectorAll('[data-social-share-toggle]');
const shareEmailModal = document.querySelector('#share-email-modal');

socialShareUrl?.addEventListener('click', (event) => {
    event.target?.select();

    navigator?.clipboard?.writeText(event.target?.value).then(
        () => {
            event.target?.classList.add('active');
            // eslint-disable-next-line no-param-reassign
            event.target.value = event.target?.getAttribute('data-success');
        }
    );
});

socialShareToggle?.forEach(button => {
    button.addEventListener('click', () => {
        socialShareContainer?.classList.toggle('active');
    });
});

// Check if there is an error in the share by email modal and open it.
if (shareEmailModal?.querySelector('.has-error')) {
    document.querySelector('[data-bs-target="#share-email-modal"]').click();
}
