import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin.js';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

const animateOnScroll = document.querySelectorAll('[data-animate]');
const sections = gsap.utils.toArray(animateOnScroll);
const sectionsNotLast = sections.slice(0, -1);
const lastSection = sections.pop();
const body = document.querySelector('body');
const scrollToTopBtn = document.querySelector('[data-scroll-to-top]');

ScrollTrigger.create({
    start       : 0,
    onUpdate    : ({ direction, progress }) => {
        const currentScroll = window.scrollTop || body.scrollTop || 0;

        body.classList.toggle('header-fixed', currentScroll > document.querySelector('header').offsetHeight);
        scrollToTopBtn.classList.toggle('active', currentScroll > window.offsetHeight);

        if (progress === 0) {
            body.classList.remove(['scroll-up', 'scroll-down']);
            scrollToTopBtn.style.display = 'none';
        } else if (direction === 1) {
            body.classList.remove('scroll-up');
            body.classList.add('scroll-down');
            scrollToTopBtn.style.display = 'block';
        } else if (direction === -1) {
            body.classList.remove('scroll-down');
            body.classList.add('scroll-up');
        }
    }
});

/* Animate sections */
sectionsNotLast.forEach((section, index) => {
    if (index > 0) {
        gsap.set(section, {
            opacity : 0,
            y       : 50
        });

        gsap.to(section, {
            scrollTrigger   : {
                trigger : section,
                start   : 'top 80%'
            },
            y           : 0,
            opacity     : 1,
            duration    : 1
        });
    }
});

if (sections > 1) {
    gsap.set(lastSection, {
        opacity : 0,
        y       : 50
    });

    gsap.to(lastSection, {
        y           : 0,
        opacity     : 1,
        duration    : 1,
        scrollTrigger: {
            trigger : lastSection,
            start   : 'bottom bottom'
        }
    });
}
