const anchorNavElements = document.querySelectorAll('.navigation-anchor');

anchorNavElements?.forEach(anchor => {
    const navItems  = anchor.querySelectorAll('li a');

    navItems?.forEach(item => {
        const href = item.getAttribute('href');
        const target = document.querySelector('[data-anchor="' + href.substring(1) + '"]');

        if (target) {
            item.addEventListener('click', () => {
                target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'});

                const siblings = item.closest('ul').querySelectorAll('li');
                siblings?.forEach(sibling => {
                    sibling.classList.remove('active');
                });

                item.closest('li').classList.add('active');
            });
        }
    });
});
